import React from 'react';
import './App.css';
import { gql } from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


function Inventries() {

    const PRODCUTS = gql`
{   inventories {
    warehouse{
      warehouse_nr
      city
    }
    warehouse_nr
    product_nr
    available
    inventories_id
  }}
`;
const classes = useStyles();
    const { loading, error, data } = useQuery(PRODCUTS, {
        pollInterval: 500,
    });
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
   
    return (
        <div>
                   <Typography variant="h4" className={classes.title}>
            Inventory
          </Typography>
          <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Product Number</TableCell>
              <TableCell align="left">Warehouse</TableCell>
              <TableCell align="left">Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.inventories.map(({inventories_id, product_nr, warehouse, warehouse_nr, available })  => (
              <TableRow key={inventories_id}>
                <TableCell component="th" scope="row">
                  {product_nr}
                </TableCell>
                <TableCell align="left">{warehouse[0]['city']}</TableCell>
                <TableCell align="left">{available}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      );
}

export default Inventries;
