import React, { useState } from 'react';
import './App.css';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';


function AddTransaction() {

  const [product_nr, setProductNr] = useState('');
  const [warehouse_nr, setWarehouseNr] = useState('');
  const [amount, setAmount] = useState('');


  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    snackbar: {
      backgroundColor: "red",
      color: "white"
    }
  }));

  const ADD_TRANSACTION = gql`
        mutation addTransaction($product_nr: String!,$warehouse_nr: Int!, $amount: Int!, ) {
            addTransaction(product_nr: $product_nr, warehouse_nr: $warehouse_nr, amount: $amount)

    }
`;

  const [addTransaction] = useMutation(ADD_TRANSACTION, {
    onError: () => {
      setOpen(true)

    }
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function submitTransaction() {
    addTransaction({ variables: { product_nr: product_nr, warehouse_nr: parseInt(Number(warehouse_nr)), amount: parseInt(Number(amount)) } });
    setProductNr("");
    setWarehouseNr("");
    setAmount("");

  }

  const classes = useStyles();
  return (
    <div align="center">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={<span id="message-id">Error invalid transaction, not added</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField value={product_nr} label="Product Nr" onChange={(e) => setProductNr(e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField value={warehouse_nr} label="Warehouse Nr" onChange={(e) => setWarehouseNr(e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField value={amount} label="Amount" onChange={(e) => setAmount(e.target.value)} />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" color="primary" className={classes.button} onClick={() => submitTransaction()}>Add Transaction</Button>
    </div>
  );

}

export default AddTransaction;
