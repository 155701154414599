import React from 'react';
import './App.css';
import { gql } from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    tableWrapper: {
      maxHeight: 440,
      overflow: 'auto',
    },
  }));

function Trabsactions() {

    const PRODCUTS = gql`
{
  transactions {
    transactions_id
    product_nr
    warehouse_nr
    amount
    created_at
  }
}

`;

const classes = useStyles();
    const { loading, error, data } = useQuery(PRODCUTS, {
        pollInterval: 500,
    }
    );
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
      // sort in reverse order
    data.transactions.reverse()

    return (
        <div>
            <Typography variant="h4" className={classes.title}>
            Transactions
          </Typography>
          <div className={classes.tableWrapper}>
          <Table className={classes.table} size="small" aria-label="Transactions">
          <TableHead>
            <TableRow>
              <TableCell align="left" >Created At</TableCell>             
              <TableCell align="left" >Product Number</TableCell>
              <TableCell align="left">Warehose</TableCell>
              <TableCell align="left">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.transactions.map(({transactions_id, product_nr, created_at, amount, warehouse_nr })  => (  
              <TableRow key={transactions_id}>
                <TableCell align="left">{new Date(parseInt(created_at)).toLocaleString('sv-SE')}</TableCell>
                <TableCell align="left">{product_nr}</TableCell>
                <TableCell align="left">{warehouse_nr}</TableCell>
                <TableCell align="left">{amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
        </div>
      );
}

export default Trabsactions;
