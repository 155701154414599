import React from 'react';
import './App.css';
import { gql } from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

function Warehouses() {

    const PRODCUTS = gql`
{   warehouses {
    warehouse_nr
    city
  }}
`;
const classes = useStyles();
    const { loading, error, data } = useQuery(PRODCUTS, {
        pollInterval: 500,
    });
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div>
            <Typography variant="h4" className={classes.title}>
            Warehouses
          </Typography>
          <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Warehouse Number</TableCell>
              <TableCell align="left">City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.warehouses.map(({ warehouse_nr, city})  => (
              <TableRow key={warehouse_nr}>
                <TableCell component="th" scope="row">
                  {warehouse_nr}
                </TableCell>
                <TableCell align="left">{city}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      );
}

export default Warehouses;
