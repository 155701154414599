import React from 'react';
import './App.css';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Products from './Products'
import AddTransaction from './AddTransaction';
import 'typeface-roboto';
import TopBar from './TopBar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Inventries from './Inventories';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import lightGreen from '@material-ui/core/colors/lightGreen';
import Warehouses from './Warehouses'

import Transactions from './Transactions';

const useStyles = makeStyles(theme => ({
  root: {
    flexgrow: 1,
    paddingTop: theme.spacing(8),
    backgroundColor: '#c2c2c2',
    height: '100vh',
    overflow: "auto"
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: ' ',
    color: theme.palette.text.secondary,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
    secondary: purple,
  },
});


function App() {

  const client = new ApolloClient({
    uri: 'https://paronab.axellundh.se/',
  });
  const classes = useStyles();


  return (

    <div className={classes.root}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <TopBar></TopBar>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.paper}><Products></Products></Paper>
              <Paper className={classes.paper}><Warehouses></Warehouses></Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.paper}><Inventries></Inventries></Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.paper}><AddTransaction></AddTransaction></Paper>
              <Paper className={classes.paper}><Transactions></Transactions></Paper>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </ThemeProvider>
      </ApolloProvider >
    </div>







  );
}

export default App;
