import React from 'react';
import './App.css';
import { gql } from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

function Products() {

  const PRODCUTS = gql`
{   products {
    product_nr
    productname
    price
  }}
`;
  const classes = useStyles();


  const { loading, error, data } = useQuery(PRODCUTS, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Products
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product Number</TableCell>
            <TableCell align="left">Product Name</TableCell>
            <TableCell align="left">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.products.map(({ product_nr, price, productname }) => (
            <TableRow key={product_nr}>
              <TableCell component="th" scope="row">
                {product_nr}
              </TableCell>
              <TableCell align="left">{productname}</TableCell>
              <TableCell align="left">{price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Products;
